import { withLDProvider } from 'launchdarkly-react-client-sdk';

const flags = {
  'appium-script': false,
  'mast-v2': false,
  'sarif-report': false,
  'load-testing-flag': false,
  'malicious-report-ios': false,
  'malicious-report-android': false,
  'product-banner': false,
  'new-quokka-report': false,
};

const providerOptions =
  process.env.REACT_APP_APPLIANCE === 'cisa'
    ? {
        user: {
          anonymous: true,
          kind: 'user',
        },
        options: {
          streaming: false,
          baseUrl: '',
          streamUrl: '',
          eventsUrl: '',
          bootstrap: flags,
        },
      }
    : {
        clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
        user: {
          anonymous: true,
          key: 'e3ed2860-7fd7-11ed-a865-fbb8600d3569',
          kind: 'user',
        },
        options: { streaming: true, bootstrap: flags },
      };

export const withLDProviderUtil = WrappedComponent =>
  withLDProvider(providerOptions)(WrappedComponent);
