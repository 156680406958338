import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QDoughnut } from '../Q-Components/QDoughnut';

export const PermissionsChartCard = ({ permissions, permissionsSummary }) => {
  const { mastV2 } = useFlags();

  const getNumberOfPermissionsBasedOnType = (results, type) => {
    const uniqueEvidencePermission = new Set();

    results.forEach(item => {
      if (item.ruleId === type && item.evidences) {
        item.evidences.forEach(evidence => {
          uniqueEvidencePermission.add(evidence.properties.permission);
        });
      }
    });

    return uniqueEvidencePermission.size;
  };

  const getNumberOfPermissionsBasedOnTypeSummary = (results, type) =>
    parseInt(_.find(results, { ruleId: type })?.resultsCount, 10);

  let numberOfMissingPermissions = 0;
  let numberOfUnusedPermissions = 0;
  let numberOfUnknownPermissions = 0;
  let numberOfUsedPermissions = 0;

  if (permissions) {
    numberOfUsedPermissions = getNumberOfPermissionsBasedOnType(
      permissions,
      'used-perm',
    );

    numberOfMissingPermissions = getNumberOfPermissionsBasedOnType(
      permissions,
      'missing-perm',
    );

    numberOfUnusedPermissions = getNumberOfPermissionsBasedOnType(
      permissions,
      'unused-perm',
    );

    numberOfUnknownPermissions = getNumberOfPermissionsBasedOnType(
      permissions,
      'unknown-perm',
    );
  }

  if (permissionsSummary) {
    numberOfMissingPermissions = getNumberOfPermissionsBasedOnTypeSummary(
      permissionsSummary,
      'missing-perm',
    );

    numberOfUnusedPermissions = getNumberOfPermissionsBasedOnTypeSummary(
      permissionsSummary,
      'unused-perm',
    );

    numberOfUnknownPermissions = getNumberOfPermissionsBasedOnTypeSummary(
      permissionsSummary,
      'unknown-perm',
    );

    numberOfUsedPermissions = getNumberOfPermissionsBasedOnTypeSummary(
      permissionsSummary,
      'used-perm',
    );
  }

  const chartData = {
    labels: [`Used`, `Missing`, `Unused`, `Unknown`],
    datasets: [
      {
        data: [
          numberOfUsedPermissions,
          numberOfMissingPermissions,
          numberOfUnusedPermissions,
          numberOfUnknownPermissions,
        ],
        backgroundColor: ['#B8DCD1', '#244C5A', '#FFA38B', '#56B093'],
      },
    ],
  };

  return (
    <QCard style={{ height: '100%' }}>
      <QCardHeader
        tag="h4"
        title={<QTypography variant="h5Medium">Permissions</QTypography>}
        style={mastV2 ? {} : { backgroundColor: '#FFFFFF' }}
      >
        Permissions
      </QCardHeader>
      <QCardBody
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '350px',
        }}
      >
        <QDoughnut data={chartData} options={{ maintainAspectRatio: false }} />
      </QCardBody>
    </QCard>
  );
};
