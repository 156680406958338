import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import { ExpandMore } from '@mui/icons-material';

import { QButton } from '../Q-Components/QButton';
import { QUncontrolledButtonDropdown } from '../Q-Components/QUncontrolledButtonDropdown';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QMenuButtonWithIcon } from '../Q-Components/QMenuButtonWithIcon';
import { QActionSelect } from '../Q-Components/QActionSelect';
import util from '../../logic/util';

const AppReports = ({ flags, app }) => {
  const { userAccess, userConfig } = useSelector(state => state.emmApp);

  const { mastV2, newQuokkaReport } = useFlags();

  const {
    farm_app: farmApp,
    id: analyzedAppId,
    pdf_report_generated: pdfReportGenerated,
  } = app;
  const { platform, uuid, id: farmAppId } = farmApp;

  const {
    sarifReport,
    maliciousReportIos,
    maliciousReportAndroid,
    appiumScript,
  } = flags;

  const maliciousReportIosVisibility = maliciousReportIos && platform === 'ios';
  const maliciousReportAndroidVisibility =
    maliciousReportAndroid && platform === 'android';

  // Allow downloading appium artifacts only if
  // feature flag enabled & user has the permission & this app has been submitted with test script
  const appiumArtifactsVisibility =
    appiumScript && userConfig.test_script_submission && app.script_name;

  const getReportLink = type =>
    `/${type}/${uuid || farmAppId}/${analyzedAppId || ''}`;

  const maliciousReportVisibility =
    sarifReport &&
    (maliciousReportIosVisibility || maliciousReportAndroidVisibility) &&
    userAccess.malicious;

  const reportOptions = [
    ...(newQuokkaReport
      ? [
          {
            label: 'New Quokka Report',
            value: 'new_quokka_report',
            icon: <i className="fa-brands fa-html5 fa-lg" />,
            link: getReportLink('new-report'),
          },
        ]
      : []),
    {
      label: 'Quokka',
      value: 'quokka',
      icon: <i className="fa-brands fa-html5 fa-lg" />,
      link: getReportLink(`${platform}-report`),
    },
    ...(maliciousReportVisibility
      ? [
          {
            label: 'Malicious',
            value: 'malicious',
            icon: <i className="fa-brands fa-html5 fa-lg" />,
            link: getReportLink('malicious-report'),
          },
        ]
      : []),
    ...(sarifReport
      ? [
          {
            label: 'SARIF',
            value: 'sarif',
            icon: <i className="fa-brands fa-html5 fa-lg" />,
            link: getReportLink('sarif-report'),
          },
          {
            label: 'SARIF JSON',
            value: 'sarif_json',
            icon: <i className="fa-solid fa-file-code fa-lg" />,
            onClick: () => util.getResults(farmApp, 'sarif'),
          },
        ]
      : []),
    {
      label: 'Quokka PDF',
      value: 'pdf',
      icon: <i className="fa-solid fa-file-pdf fa-lg" />,
      onClick: () => util.getResults(farmApp, 'pdf'),
      disabled: !pdfReportGenerated,
    },
    ...(userAccess.niap
      ? [
          {
            label: 'NIAP',
            icon: <i className="fa-solid fa-file-code fa-lg" />,
            onClick: () => util.getResults(farmApp, 'niap_json'),
          },
        ]
      : []),
    {
      label: 'JSON',
      value: 'json',
      icon: (
        <i
          className="fa-regular fa-circle-info fa-lg"
          style={{ color: '#1976d2' }}
        />
      ),
      onClick: () => util.getResults(farmApp, 'json'),
    },
    {
      label: 'JSON Zip',
      value: 'json_compressed',
      icon: (
        <i
          className="fa-solid fa-file-zipper fa-lg"
          style={{ color: '#1976d2' }}
        />
      ),
      onClick: () => util.getResults(farmApp, 'json_compressed'),
    },
    {
      label: 'Network JSON',
      value: 'traffic',
      icon: <i className="fa-regular fa-wifi" style={{ color: '#fbc02d' }} />,
      onClick: () => util.getResults(farmApp, 'traffic'),
    },
    ...(userAccess.custom_report
      ? [
          {
            label: 'DISA',
            value: 'disa',
            icon: (
              <i
                className="fa-regular fa-asterisk"
                style={{ color: '#fbc02d' }}
              />
            ),
            onClick: () => util.getResults(farmApp, 'disa'),
          },
        ]
      : []),
    {
      label: 'App Manifest',
      value: 'manifest',
      icon: <i className="fa-regular fa-clipboard-list primary-icon" />,
      onClick: () => util.getResults(farmApp, 'manifest'),
    },
    ...(appiumArtifactsVisibility
      ? [
          {
            label: 'Appium Artifacts',
            value: 'appium',
            icon: <i className="fa-solid fa-file-zipper fa-lg" />,
            onClick: () => util.getResults(farmApp, 'appium'),
          },
        ]
      : []),
  ];

  return (
    <>
      {mastV2 ? (
        <div className="report-action-dropdown">
          <QActionSelect
            actionOptions={reportOptions}
            label="View Reports"
            width="140px"
          />
        </div>
      ) : (
        <QUncontrolledButtonDropdown
          variant="filled"
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
          className="mx-1 report-action-dropdown"
          title="Reports"
          dropdown
          sx={{ width: '140px' }}
        >
          <QDropdownToggle
            caret
            className="toggledown-primary"
            size="sm"
            disableAnimation
            shrink={false}
          >
            <QButton
              variant="outlined"
              endIcon={<ExpandMore />}
              className="button-toggledown-primary"
            >
              View Reports
            </QButton>
          </QDropdownToggle>

          <QDropdownMenu
            caret
            variant="filled"
            IconComponent={null}
            value=""
            displayEmpty
            renderValue={() => {}}
          >
            {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
            <QDropdownSelectItem value="" style={{ display: 'none' }} />
            {/* <DropdownItem header>{i18n.t('Reports')}</DropdownItem> */}
            {/* Quokka HTML Reports */}
            {reportOptions.map(reportOption =>
              reportOption.link ? (
                <Link
                  key={reportOption.value}
                  to={reportOption.link}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <QDropdownSelectItem>
                    <QMenuButtonWithIcon
                      icon={reportOption.icon}
                      label={reportOption.label}
                    />
                  </QDropdownSelectItem>
                </Link>
              ) : (
                <QDropdownSelectItem
                  key={reportOption.value}
                  onClick={reportOption.onClick}
                  disabled={reportOption.disabled}
                >
                  <QMenuButtonWithIcon
                    icon={reportOption.icon}
                    label={reportOption.label}
                  />
                </QDropdownSelectItem>
              ),
            )}
          </QDropdownMenu>
        </QUncontrolledButtonDropdown>
      )}
    </>
  );
};

export default withLDConsumer()(AppReports);
