import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import filesize from 'filesize';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { toastrHelper } from '../logic/toastrHelper';

import { QTypography } from './Q-Components/QTypography';
import { QContainer } from './Q-Components/QContainer';
import { Spinner } from './Spinner';

export const Downloader = () => {
  const { mastV2 } = useFlags();
  const { pathname, search } = useLocation();
  const [downloadedSize, setDownloadedSize] = useState(0);
  const [completedFile, setCompletedFile] = useState('');
  const [error, setError] = useState('');

  const downloadFile = async () => {
    toastrHelper.showInfoToast(`Prepare to download`, 'Downloader', mastV2);

    try {
      let filePath = `${pathname.replace('/download', '')}${search}`;

      if (filePath.includes('/firmware')) {
        filePath = `${process.env.REACT_APP_FIRMWARE_SERVICE_API}${filePath}`;
        filePath = filePath.replace('/firmware', '');
      }

      toastrHelper.showWarningToast(
        'Downloading file...',
        'Downloader',
        mastV2,
      );
      const response = await axios.get(filePath, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          setDownloadedSize(progressEvent.loaded);
        },
      });

      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, '')
        : 'downloaded_file'; // Default filename if header is not set

      // Determine the MIME type from the Content-Type header
      const contentType = response.headers['content-type'];

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      toastrHelper.showSuccessToast(
        'File downloaded successfully',
        'Downloader',
        mastV2,
      );
      setCompletedFile(filename);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error downloading file',
        'Downloader',
        mastV2,
      );

      setError(err.message);
    } finally {
      setTimeout(() => window.close(), 5000);
    }
  };

  useEffect(() => {
    downloadFile();
  }, []);

  return (
    <QContainer fluid>
      {completedFile ? (
        <QTypography>Download file: {completedFile} completed</QTypography>
      ) : error ? (
        <QTypography>Download error: {error}</QTypography>
      ) : (
        <QTypography>
          Downloading... {filesize(downloadedSize)} <Spinner />
        </QTypography>
      )}
    </QContainer>
  );
};
