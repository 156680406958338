/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTypography } from '../Q-Components/QTypography';
import { QBadge } from '../Q-Components/QBadge';
import { QButton } from '../Q-Components/QButton';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QDivider } from '../Q-Components/QDivider';
import { aggregateResultsFromRuleId } from '../../logic/dopplerUtil';
import { toastrHelper } from '../../logic/toastrHelper';

import RiskLevel from './SimpleReport/ReportFindingCard/RiskLevel';
import { Regulations } from './SimpleReport/ReportFindingCard/Regulations';
import { EvidenceV2 } from './SimpleReport/ReportFindingCard/EvidenceV2/EvidenceV2';

export function IssueCard({ ruleSummary, uuid }) {
  const { mastV2 } = useFlags();
  const isNotEmpty = regulations => regulations && !_.isEmpty(regulations);

  const [isExpanded, setIsExpanded] = useState(false);
  const [evidence, setEvidence] = useState(null);

  const issue = aggregateResultsFromRuleId(
    [ruleSummary.rule],
    [
      {
        ruleId: ruleSummary.rule.id,
        level: ruleSummary.rule.defaultConfiguration?.level,
      },
    ],
  )[0];

  const fetchEvidence = async () => {
    try {
      const { data } = await axios.get(
        `/analysis-results/evidence?uuid=${uuid}&ruleGuid=${ruleSummary.guid}`,
      );
      const evidence = data.map(evidence => evidence.result);

      const formattedEvidence = aggregateResultsFromRuleId(
        [ruleSummary.rule],
        evidence,
      )[0];
      setEvidence(formattedEvidence);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading evidence data',
        'Error',
        mastV2,
      );
    }
  };

  const handleAccordionChange = nextState => {
    setIsExpanded(nextState);
    if (nextState && !evidence) {
      fetchEvidence();
    }
  };

  const showDescription = Array.isArray(issue?.name)
    ? issue?.name[0] !== issue?.description
    : issue?.name !== issue?.description;

  return issue ? (
    <Accordion
      style={{
        backgroundColor: '#FAFAFA',
        boxShadow: 'none',
        borderRadius: '0px',
      }}
      data-testid="rule-finding"
      expanded={isExpanded}
      onChange={() => handleAccordionChange(!isExpanded)}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ marginTop: '12px', marginBottom: '12px' }} />
        }
        style={{
          flexDirection: 'row-reverse',
          alignItems: 'start',
        }}
      >
        <QCol
          style={{
            marginLeft: '16px',
            marginTop: '3px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
          }}
        >
          <QRow>
            <QTypography variant="h5Regular">{issue.name}</QTypography>
          </QRow>

          <QRow columnGap="8px">
            <QBadge
              variant="basic"
              style={{
                backgroundColor: 'purple',
                height: '24px',
              }}
              label={`${ruleSummary?.occurrences} occurrences`}
            />
            <RiskLevel risk={issue.risk} />
            {issue.exploitable && (
              <QBadge
                variant="basic"
                style={{ backgroundColor: '#3B3E40', height: '24px' }}
                label="Exploitable"
              />
            )}
            {issue.cvss_score && (
              <QButton
                variant="outlined"
                endIcon={<LaunchIcon />}
                href={issue.cvss_link}
                style={{
                  fontSize: '12px',
                  height: '24px',
                }}
              >
                CVSS: {issue.cvss_score}
              </QButton>
            )}
            {issue.cwe && (
              <QButton
                variant="outlined"
                endIcon={<LaunchIcon />}
                href={issue.cwe_link}
                style={{
                  fontSize: '12px',
                  height: '24px',
                }}
              >
                {issue.cwe.split(':')[0]}
              </QButton>
            )}
          </QRow>
          {showDescription && (
            <QRow>
              <QTypography
                variant="h6Medium"
                style={{
                  color: '#757575',
                }}
              >
                {i18n.t(issue.description)}
              </QTypography>{' '}
            </QRow>
          )}
        </QCol>
      </AccordionSummary>
      <AccordionDetails>
        {evidence && (
          <QRow
            columnGap="16px"
            style={{
              width: '100%',
              paddingLeft: '40px',
              marginBottom: '16px',
            }}
          >
            {(evidence.threat_profile ||
              evidence.remediation ||
              evidence.impact) && (
              <QCard
                style={{
                  padding: '8px',
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                }}
              >
                <QCardBody
                  style={{
                    padding: '30px',
                  }}
                >
                  {evidence.threat_profile && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Threat Details
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(evidence.threat_profile)}
                      </p>
                    </QCol>
                  )}
                  {evidence.threat_profile && evidence.remediation && (
                    <QDivider
                      style={{
                        borderColor: '#E0E0E0',
                        marginTop: '14px',
                        marginBottom: '14px',
                      }}
                    />
                  )}
                  {evidence.remediation && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Remediation
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(evidence.remediation)}
                      </p>
                    </QCol>
                  )}
                  {evidence.impact &&
                    (evidence.remediation || evidence.threat_profile) && (
                      <QDivider
                        style={{
                          borderColor: '#E0E0E0',
                          marginTop: '14px',
                          marginBottom: '14px',
                        }}
                      />
                    )}
                  {evidence.impact && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Finding Impact
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(evidence.impact)}
                      </p>
                    </QCol>
                  )}
                </QCardBody>
              </QCard>
            )}
            {(isNotEmpty(evidence.owasp) ||
              isNotEmpty(evidence.niap) ||
              isNotEmpty(evidence.gdpr)) && (
              <QCard
                style={{
                  padding: '8px',
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                }}
              >
                <QCardHeader
                  disableTypography
                  title={
                    <QTypography
                      variant="h5Bold"
                      style={{
                        color: '#000000',
                      }}
                    >
                      Compliance
                    </QTypography>
                  }
                />
                <QCardBody>
                  <Regulations issue={evidence} />
                </QCardBody>
              </QCard>
            )}
          </QRow>
        )}

        {isNotEmpty(evidence) && (
          <QCard
            style={{
              padding: '0',
              marginLeft: '40px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <QCardHeader disableTypography title="Evidence" />
            <QDivider />
            <QCardBody>
              <EvidenceV2 issue={evidence} />
            </QCardBody>
          </QCard>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null;
}
export default withRouter(IssueCard);
