export const sortIssuesByRisk = (a, b) => {
  const weights = {
    critical: 3,
    high: 2,
    medium: 1,
    low: 0,
  };

  if (weights[a.risk] > weights[b.risk]) return -1;
  if (weights[a.risk] < weights[b.risk]) return 1;
  return 0;
};

export const getThreatIconClass = risk => {
  if (risk === 'high' || risk === 'error') {
    return 'danger-icon';
  }
  if (risk === 'critical') {
    return 'critical-icon';
  }
  if (risk === 'medium' || risk === 'warning') {
    return 'warning-icon';
  }

  return '';
};

export const getThreatColor = risk => {
  if (risk === 'high' || risk === 'error') {
    return 'danger';
  }
  if (risk === 'critical') {
    return 'critical';
  }
  if (risk === 'medium' || risk === 'warning') {
    return 'warning';
  }
  if (risk === 'low' || risk === 'note') {
    return 'secondary';
  }
  if (risk === 'informational') {
    return 'info';
  }

  return '';
};

export const getThreatQBadgeVariant = risk => {
  if (risk === 'high' || risk === 'error') {
    return 'red';
  }
  if (risk === 'critical') {
    return 'red';
  }
  if (risk === 'medium' || risk === 'warning') {
    return 'orange';
  }
  if (risk === 'low' || risk === 'note') {
    return 'blue';
  }
  if (risk === 'informational') {
    return undefined;
  }

  return undefined;
};
