import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import fp from 'lodash/fp';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { DateTime } from '../DateTime';
import i18n from '../../localization/i18n';
import { ThreatScore } from '../ThreatScore';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';

import SummarizedAppInfo, { byVersion } from './SummarizedAppInfo';
import { BatchChangeOwnershipModal } from './BatchChangeOwnershipModal';
import SummarizedAppActions from './SummarizedAppActions';
import { ThreatScoreSparkline, SparklineData } from './ThreatScoreSparkline';
import RisksBarChart from './RisksBarChart';

/**
 * Analyzed App component
 * @param {*} props
 */
const SummarizedApp = ({ app, userAccess, isMdmApp, removeApp, getApps }) => {
  const [showChangeOwnershipModal, setShowChangeOwnershipModal] =
    useState(false);
  const { columnSettings } = useSelector(state => state.emmApp);

  const threatScores = SparklineData(
    { x: 'version', y: 'threat_score', label: 'Risk Score' },
    app.analyzed_apps
      .filter(app => app.threat_score)
      .map(fp.pickAll(['threat_score', 'version'])),
    'rgb(0, 122, 255)',
  );

  const riskLevels = _.chain(app.risk_levels)
    .groupBy('version')
    .mapValues(data => data[0].levels)
    .value();

  const lastAnalyzedApp = app.analyzed_apps.reduce((latest, current) => {
    const versionComparison = byVersion(current.version, latest.version);

    if (versionComparison > 0) {
      return current;
    }
    if (versionComparison === 0) {
      const currentDate = new Date(current.submitted_at);
      const latestDate = new Date(latest.submitted_at);

      return currentDate > latestDate ? current : latest;
    }
    return latest;
  }, app.analyzed_apps[0]);

  return (
    <QTableRow>
      {/* App data */}
      <QTableCell>
        <Link
          to={`/analyzed-app/${app.analyzed_apps[0].id}/all-versions`}
          component={SummarizedAppInfo}
          app={app}
          isMdmApp={isMdmApp}
        />
      </QTableCell>

      {/* Date Submitted */}
      {columnSettings.dateCol && (
        <QTableCell className="text-center">
          <DateTime dateTime={lastAnalyzedApp.submitted_at} />
        </QTableCell>
      )}

      {/* Threat Score */}
      {columnSettings.scoreCol && (
        <QTableCell className="text-center">
          {threatScores.values.length <= 1 ? (
            <div className="d-flex justify-content-center">
              <div className="text-center">
                {app.analyzed_apps.find(app => app.threat_score) && (
                  <div className="text-center">
                    <ThreatScore
                      threatScore={
                        app.analyzed_apps.find(app => app.threat_score)
                          .threat_score
                      }
                      status={2}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ThreatScoreSparkline data={threatScores} />
          )}
        </QTableCell>
      )}

      {/* App Issues */}
      {columnSettings.findingsCol ? (
        <QTableCell>
          <RisksBarChart data={riskLevels} />
        </QTableCell>
      ) : null}

      {/* Actions */}
      <QTableCell className="text-center">
        <>
          {userAccess.change_sub_group_ownership_of_apps && (
            <BatchChangeOwnershipModal
              isOpen={showChangeOwnershipModal}
              toggle={() =>
                setShowChangeOwnershipModal(!showChangeOwnershipModal)
              }
              analyzedAppIds={app.analyzed_apps.map(app => app.id)}
              lastAnalyzedAppId={lastAnalyzedApp.id}
              analyzedAppSubgroups={app.subgroup_ids}
              getApps={getApps}
            />
          )}
          <div>
            <SummarizedAppActions
              app={app}
              analyzedAppIds={app.analyzed_apps.map(app => app.id)}
              lastAnalyzedAppId={lastAnalyzedApp.id}
              removeApp={removeApp}
              setShowChangeOwnershipModal={setShowChangeOwnershipModal}
              getApps={getApps}
            />

            {(app.belongsToUser || userAccess.group_admin) && (
              <>
                {app.has_analysis_issue && (
                  <UncontrolledTooltip
                    placement="top"
                    target={`resubmit-btn-app-${app.id}`}
                  >
                    {i18n.t(
                      'We have detected that there may have been an issue with the analysis and suggest that the app be re-submitted.',
                    )}
                  </UncontrolledTooltip>
                )}
              </>
            )}
          </div>

          {app.app_note && app.app_note.note && (
            <div
              style={{
                textAlign: 'center',
                borderRadius: 4,
                border: '1px solid #c4c4c4',
              }}
              className="p-2 mt-3"
            >
              <div>
                <strong>{i18n.t('Note')}:</strong>
                <br />
                <span>{app.app_note.note}</span>
              </div>
            </div>
          )}
        </>
      </QTableCell>
    </QTableRow>
  );
};

const mapStateToProps = ({ emmApp }) => ({
  userAccess: emmApp.userAccess,
  lang: emmApp.portalConfig.lang,
});

const Connected = connect(mapStateToProps)(SummarizedApp);
export default React.forwardRef((props, ref) => (
  <Connected {...props} innerRef={ref} />
));
