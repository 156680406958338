import _ from 'lodash';

export const getSeverity = score => {
  let severity = 'low';
  if (score >= 7) {
    severity = 'high';
  } else if (score >= 4) {
    severity = 'moderate';
  }

  return severity;
};

export const getResults = data => {
  const results = [];
  if (data.runs && data.runs.length > 0) {
    for (let i = 0; i < data.runs.length; i++) {
      const runsResults = _.get(data.runs[i], 'results', []);
      results.push(...runsResults);
    }
  }
  return results;
};

export const getRules = data => {
  const rules = [];
  if (data.runs && data.runs.length > 0) {
    for (let i = 0; i < data.runs.length; i++) {
      const runsRules = _.get(data.runs[i], 'tool.driver.rules', []).map(
        rule => ({
          ...rule,
          driverName: _.get(data.runs[i], 'tool.driver.name', ''),
        }),
      );
      rules.push(...runsRules);
    }
  }
  return rules;
};

const findRule = (results, ruleId) => {
  const rules = getRules(results);

  return rules.find(rule => rule.id === ruleId);
};

export const getRuleName = (results, ruleId) => {
  const selectedRule = findRule(results, ruleId);

  return selectedRule?.shortDescription?.text ?? '';
};

export const getCWEs = (results, ruleId) => {
  const selectedRule = findRule(results, ruleId);

  return (
    selectedRule?.properties?.tags?.filter(tag => tag.includes('CWE')) ?? []
  );
};

const sortFindings = (a, b) => {
  const riskOrder = {
    error: 0,
    warning: 1,
    note: 2,
    informational: 3,
  };

  const riskComparison = riskOrder[a.risk] - riskOrder[b.risk];

  if (riskComparison !== 0) {
    return riskComparison;
  }

  // If risk levels are equal, compare by ruleId
  return a.ruleId.localeCompare(b.ruleId);
};
const processRiskLevel = (kind, level) => {
  // kind defaults to fail if it doesn't exist
  if (!kind || _.lowerCase(kind) === 'fail') {
    // level defaults to warning if it's not set when kind is fail
    if (!level) {
      return 'warning';
    }

    return _.lowerCase(level) === 'none' ? 'informational' : _.lowerCase(level);
  }
  return 'informational';
};

const normalizeId = id => id.toLowerCase().trim();

// This function will merge all evidences of all findings by the same rule id in the result object
// and also merge all the properties we need to render from the rules found in the tools object
// Result will be { rasp-not-detected: [{}], [{}]}
export const aggregateResultsFromRuleId = (rules, results) => {
  // create an object that has all ruleIds {ruleId1: [], ruleId2: []}
  const ruleFindingsMap = {};
  if (!_.isEmpty(results)) {
    results.forEach(result => {
      const { ruleId } = result;
      if (!Object.prototype.hasOwnProperty.call(ruleFindingsMap, ruleId)) {
        ruleFindingsMap[ruleId] = [];
      }
      ruleFindingsMap[ruleId].push(result);
    });
  }

  const detailResultFromRules = [];

  rules.forEach(rule => {
    const { niap = [], gdpr = [] } = rule.properties?.compliance || {};

    const owasp =
      rule.properties?.compliance?.['owasp-2016'] ||
      rule.properties?.compliance?.owasp ||
      [];

    const normalizedRuleId = normalizeId(rule.id);

    // Find matching rule ids, considering rule.id could be a prefix
    const matchingRuleIds = Object.keys(ruleFindingsMap).filter(resultRuleId =>
      resultRuleId.startsWith(normalizedRuleId),
    );

    if (matchingRuleIds.length > 0) {
      // Aggregate all results for the matching rule IDs
      const allEvidences = matchingRuleIds.flatMap(
        matchingRuleId => ruleFindingsMap[matchingRuleId],
      );

      // Determine risk level based on the first result's kind and level of severity
      const riskLevel = processRiskLevel(
        allEvidences[0].kind,
        allEvidences[0].level,
      );

      detailResultFromRules.push({
        name: [
          rule.shortDescription?.text ??
            rule.name ??
            // first sentence of fullDescription should be descriptive enough in case both previous values are not set
            // https://docs.oasis-open.org/sarif/sarif/v2.1.0/os/sarif-v2.1.0-os.html#_Toc34317846
            rule.fullDescription?.text?.split('.')[0],
        ],
        driverName: rule.driverName || rule.tool,
        description: rule.fullDescription?.text ?? ' ',
        niap,
        owasp,
        gdpr,
        ...rule.properties,
        risk: riskLevel,
        evidences: allEvidences,
        ruleId: rule.id,
      });
    }
  });
  detailResultFromRules.sort(sortFindings);

  return detailResultFromRules;
};

export const getMaliciousThreatLevel = data => {
  if (data && data.evidences && data.evidences.length > 0) {
    return data.evidences[0].properties.threatLevel;
  }
  return -1;
};

export const getMaliciousThreatTypes = data => {
  if (data && data.evidences && data.evidences.length > 0) {
    return data.evidences[0].properties.threatTypes ?? {};
  }
  return {};
};
