import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';
import { QDoughnut } from '../Q-Components/QDoughnut';

export const IorsChartCard = ({ results, resultSummary, title = 'IORs' }) => {
  const { mastV2 } = useFlags();

  const getNumberOfIorsBasedOnRiskLevel = (results, level) =>
    results.reduce((acc, item) => (item.risk === level ? acc + 1 : acc), 0);

  const getNumberOfIorsBasedOnRiskLevelSummary = (results, level) =>
    parseInt(_.find(results, { level })?.resultsCount, 10) || 0;

  let numberOfNoteIors = 0;
  let numberOfWarningIors = 0;
  let numberOfErrorIors = 0;

  if (results) {
    numberOfNoteIors = getNumberOfIorsBasedOnRiskLevel(results, 'note');

    numberOfWarningIors = getNumberOfIorsBasedOnRiskLevel(results, 'warning');

    numberOfErrorIors = getNumberOfIorsBasedOnRiskLevel(results, 'error');
  }

  if (resultSummary) {
    numberOfNoteIors = getNumberOfIorsBasedOnRiskLevelSummary(
      resultSummary,
      'note',
    );
    numberOfWarningIors = getNumberOfIorsBasedOnRiskLevelSummary(
      resultSummary,
      'warning',
    );
    numberOfErrorIors = getNumberOfIorsBasedOnRiskLevelSummary(
      resultSummary,
      'error',
    );
  }
  const chartData = {
    labels: [`Error`, `Warning`, `Note`],
    datasets: [
      {
        data: [numberOfErrorIors, numberOfWarningIors, numberOfNoteIors],
        backgroundColor: ['#F44336', '#FF9800', '#2196F3'],
      },
    ],
  };

  return (
    <QCard style={{ height: '100%' }}>
      <QCardHeader
        tag="h4"
        title={<QTypography variant="h5Medium">{title}</QTypography>}
        style={mastV2 ? {} : { backgroundColor: '#FFFFFF' }}
      >
        {title}
      </QCardHeader>
      <QCardBody
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '350px',
        }}
      >
        <QDoughnut data={chartData} options={{ maintainAspectRatio: false }} />
      </QCardBody>
    </QCard>
  );
};
