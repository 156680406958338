/* istanbul ignore file */
import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import JoyTour from '../../components/JoyTour';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import { AnalyzedAppsNavTabs } from '../../components/AnalyzedApps/AnalyzedAppsNavTabs';
import AppAnalysisApprovals from '../../components/AppApprovals/AppAnalysisApprovals';
import SubmitApp from '../../views/SubmitApp';
import AppWatchlist from '../../views/AppWatchlist';
import CustomizeThreat from '../../views/CustomizeThreat';
import DocumentationContainer from '../../views/DocumentationContainer';
import Analytics from '../../views/Analytics';
import AndroidStandardReport from '../../views/Reports/AndroidStandard';
import IosStandardReport from '../../views/Reports/IosStandard';
import { Settings } from '../../views/Settings';
import CsvDownloads from '../../views/CsvDownloads';
import AppAllVersions from '../../views/AppAllVersions';
import AppCompareVersions from '../../views/AppCompareVersions';
import MdmIntegration from '../../views/MdmIntegration';
import MdmDeviceDetails from '../../views/MdmDeviceDetails';
import Notifications from '../../views/Notifications';
import auth from '../../logic/auth';
import {
  setUserNotifications,
  setMaxSubmissionsReached,
  setTotalSubmissions,
  setLicenseInfo,
  setUserAccessData,
  setPasswordChangeOpen,
  setTwoFactorAuthEnabled,
  setIsTrialUser,
  setExpiredDate,
  setUserConfigData,
  setUserSubGroups,
  setGroupAdminUsers,
  setGroupAdminSubGroups,
  setGroupAdminGroup,
  setGroupNotifications,
} from '../../store/slices/emmAppSlice';
import {
  fetchGroupAdminUsers,
  fetchGroupAdminSubGroups,
  fetchGroupAdminGroup,
} from '../../store/sliceHelpers/emmAppSliceHelper';
import { setThreatItems } from '../../store/slices/threatProfileSlice';
import { Banner } from '../../components/Banner/Banner';
import DemoAlerts from '../../components/DemoAlerts';
import LicenseWarning from '../../components/LicenseWarning';
import Loading from '../../components/Loading';
import AnalyzedFirmware from '../../views/AnalyzedFirmware';
import { FirmwareApps } from '../../views/FirmwareApps';
import DopplerResults from '../../components/Reports/Android/DopplerResults';
import i18n from '../../localization/i18n';
import KAI from '../../components/KAI/KAI';
import LaunchDarklyIdentifier from '../../components/LaunchDarklyIdentifier';
import { IdleTimer } from '../../components/IdleTimer';
import { SarifReport } from '../../views/Reports/SarifReport';
import { GroupNotifications } from '../../components/GroupNotifications';
import { toastrHelper } from '../../logic/toastrHelper';
import { MaliciousReport } from '../../views/Reports/MaliciousReport';
import { NewReport } from '../../views/Reports/NewReport';
import { Downloader } from '../../components/Downloader';
import { getBanners } from '../../components/Banner/Banners';
import { clearAllCache } from '../../store/slices/cache/clearAllCache';
import { MAVMonthlyStatistics } from '../../views/MAVMonthlyStatistics';

class Full extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      email: '',
      config: {},
      access: {},
      groupId: 0,

      // Product tour
      isTourActive: false,
    };

    console.log(this.props.flags);

    // Set the axios interceptor to check and see if we got a 401 error
    // If so, we'll auto-logout
    axios.interceptors.response.use(
      response => response,
      err => {
        console.log('Axios intercepted err:', err);
        // Check for 401 (unauthorized error), ignore responses from
        // attempts to login, since that is handled separately inside
        // the login component
        if (
          err.response &&
          err.response.status === 401 &&
          !err.request.responseURL.endsWith('emm/login')
        ) {
          console.log('Unauthorized error received, logging out...');
          auth.logout();
          this.props.clearAllCache();

          toastrHelper.showWarningToast(
            i18n.t('Session is expired'),
            i18n.t('Logged out'),
            this.props.flags.mastV2,
          );

          auth.redirectToLogin(this.props.history);
        }
        console.log('Axios error:', err.response);

        return Promise.reject(err);
      },
    );

    this.checkAuth = this.checkAuth.bind(this);
    this.restartTour = this.restartTour.bind(this);

    this.checkAuth();
  }

  componentDidMount() {
    // We use a callback here to make sure the user's info was grabbed
    // and set from the call to verify-auth before checking to see if
    // we should start the tour or not
    this.checkAuth(() => {
      // If the user hasn't taken the tour setup the tour start
      if (!this.state.config.tour_taken) {
        setTimeout(() => {
          this.setState({
            isTourActive: true,
          });
        }, 1000);
      }
    });

    this.getUserNotifications();
    this.getThreatScoreInfo();
    this.getLicenseInfo();
    this.getGroupNotifications();
  }

  // Gets the current threat score profile information. This is used for determining
  // custom threat level for items. We are by default displaying items in the summary
  // section which the user has given a threat level of "high"
  async getThreatScoreInfo() {
    const infoResponse = await axios.get('emm/threat-score-info');
    this.props.setThreatItems(infoResponse?.data?.threatItems ?? []);
  }

  // Get any notifications that should be shown for the user
  async getUserNotifications() {
    const response = await axios.get('notifications');
    this.props.setUserNotifications(response?.data?.notifications ?? []);
  }

  // Get license info
  async getLicenseInfo() {
    const response = await axios.get('license/utilization');

    if (response) {
      this.props.setLicenseInfo(response.data);
    }
  }

  // Get group notification
  async getGroupNotifications() {
    try {
      const { data } = await axios.get('user-groups-notifications');

      this.props.setGroupNotifications(data);
    } catch (err) {
      console.log(err);
    }
  }

  // Restart the Joyride tour
  async restartTour() {
    console.log('Restarting tour');
    if (this.state.isTourActive) {
      const response = await axios.put('emm/config', {
        config_key: 'tour_taken',
        value: 0,
      });
      if (response.status !== 200) {
        console.error(response);
      }
      this.props.history.push('/dashboard');
      window.location.reload();
    } else {
      this.props.history.push('/dashboard');
    }
    this.setState({ isTourActive: true });
  }

  buildSession = ({
    config,
    twoFactorAuthEnabled,
    isTrialUser,
    expiresOn,
    passwordChangeNeeded,
    access,
  }) => {
    const {
      setUserAccessData,
      setUserConfigData,
      setTwoFactorAuthEnabled,
      setIsTrialUser,
      setExpiredDate,
      setPasswordChangeOpen,
    } = this.props;

    setUserAccessData(access);
    setUserConfigData(config);
    setTwoFactorAuthEnabled(twoFactorAuthEnabled);
    setIsTrialUser(isTrialUser);
    setExpiredDate(expiresOn);

    // Opens the password change modal if the user needs a password change
    // Ignore if user has sso login enabled
    if (!access.sso_login) {
      setPasswordChangeOpen(passwordChangeNeeded);
    }
  };

  // Check the user's auth status, also sets up the app's state for user info
  async checkAuth(callback) {
    const {
      setMaxSubmissionsReached,
      setTotalSubmissions,
      setUserSubGroups,
      setGroupAdminUsers,
      setGroupAdminSubGroups,
      fetchGroupAdminUsers,
      fetchGroupAdminSubGroups,
      fetchGroupAdminGroup,
      setGroupAdminGroup,
      clearAllCache,
    } = this.props;

    if (auth.getToken()) {
      try {
        // Make sure to set the global axios header on first load if it's present in local storage
        axios.defaults.headers.common.Authorization = auth.getToken();
        const response = await axios.get('emm/verify-auth');

        this.buildSession(response.data);

        // Setup if the user has exceeded their max app submissions
        const { androidSubmissions, iosSubmissions, access } = response.data;

        const totalSubmissions = androidSubmissions + iosSubmissions;
        const reachedAppSubmission =
          androidSubmissions + iosSubmissions >= access.max_submissions &&
          access.max_submissions > 0;

        setMaxSubmissionsReached(reachedAppSubmission);
        setTotalSubmissions(totalSubmissions);
        setUserSubGroups(response.data.subGroups);

        // Call group admin users and subgroups if user is admin
        if (access.group_admin) {
          console.log('Getting admin users and sub groups');
          fetchGroupAdminUsers();
          fetchGroupAdminSubGroups();
          fetchGroupAdminGroup();
        } else {
          setGroupAdminUsers([]);
          setGroupAdminSubGroups([]);
          setGroupAdminGroup([]);
        }

        this.setState(
          {
            email: response.data.email,
            config: response.data.config,
            name: response.data.name,
            groupId: response.data.groupId,
            access,
          },
          callback || undefined,
        );
      } catch (err) {
        console.error('Error verifying auth:', err);
        auth.logout();
        clearAllCache();
        auth.redirectToLogin(this.props.history);
      }
    } else {
      console.log('No auth token');

      auth.logout();
      clearAllCache();
      auth.redirectToLogin(this.props.history);
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, isTourActive, email, name, access, config, groupId } =
      this.state;
    if (loading) {
      return (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </div>
      );
    }

    const { mastV2, productBanner, newQuokkaReport } = this.props.flags;

    const appliance = process.env.REACT_APP_APPLIANCE || 'default';

    const isCisaAppliance = appliance === 'cisa';

    const newUIBanner = getBanners().newUIBanner[mastV2];

    return (
      <div
        className={`app ${
          mastV2
            ? this.props.sidebarCollapsed
              ? 'sidebar-compact'
              : 'sidebar-fixed'
            : 'sidebar-fixed'
        }`}
      >
        {isCisaAppliance && <IdleTimer />}
        <LaunchDarklyIdentifier
          email={email}
          id={access.userId}
          groupId={groupId}
        />
        <JoyTour isTourActive={isTourActive} name={name} />

        <Header email={email} restartTour={this.restartTour} />

        <div className="app-body">
          <Sidebar
            appApprovalEnabled={config.app_approval_enabled}
            {...this.props}
          />
          <main className="main">
            <Container fluid>
              {/* This banner will only renders in MAST V1 for a period 
              and will be cleaned up when we switch to MAST V2 officially */}
              {productBanner && (
                <Banner
                  title={newUIBanner.title}
                  message={newUIBanner.message}
                />
              )}
              <DemoAlerts />
              <LicenseWarning />
              <GroupNotifications />
              <Switch>
                {access.can_view_analyzed_apps && (
                  <Route
                    path="/dashboard"
                    exact
                    name="AnalyzedApps"
                    render={props => (
                      <AnalyzedAppsNavTabs active="2" {...props} />
                    )}
                  />
                )}
                {access.can_view_analyzed_apps && (
                  <Route
                    path="/dashboard/summary"
                    exact
                    name="SummarizedApps"
                    render={props => (
                      <AnalyzedAppsNavTabs active="1" {...props} />
                    )}
                  />
                )}
                {access.kai_access && (
                  <Route path="/kai" render={() => <KAI />} />
                )}
                {access.kai_access && (
                  <Route
                    path="/admin/analytics"
                    name="Analytics"
                    render={props => <Analytics admin {...props} />}
                  />
                )}
                {access.kai_access && isCisaAppliance && (
                  <Route
                    path="/admin/mav-monthly-statistics"
                    name="Mav Monthly Statistics"
                    render={props => <MAVMonthlyStatistics {...props} />}
                  />
                )}
                <Route
                  path="/submit-app"
                  name="SubmitApp"
                  render={props => <SubmitApp {...props} />}
                />
                <Route
                  path="/watchlist"
                  name="Watchlist"
                  render={props => <AppWatchlist {...props} />}
                />
                <Route
                  path="/customize-threat-score"
                  name="Customize Threat Score"
                  render={props => <CustomizeThreat {...props} />}
                />
                <Route
                  path="/analytics"
                  name="Analytics"
                  render={props => <Analytics {...props} />}
                />
                <Route
                  path="/app-analysis-approvals"
                  name="App Analysis Approvals"
                  render={() => (
                    <AppAnalysisApprovals
                      appApprovalEnabled={config.app_approval_enabled}
                    />
                  )}
                />
                <Route
                  path="/documentation"
                  name="Documentation"
                  render={props => <DocumentationContainer {...props} />}
                />
                <Route
                  path="/android-report/:farmAppId/:analyzedAppId?"
                  name="Android Report"
                  component={AndroidStandardReport}
                />
                <Route
                  path="/ios-report/:farmAppId/:analyzedAppId?"
                  name="iOS Report"
                  component={IosStandardReport}
                />
                <Route
                  path="/sarif-report/:farmAppUuid/:analyzedAppId"
                  name="Sarif Report"
                  component={SarifReport}
                />
                {access.malicious && (
                  <Route
                    path="/malicious-report/:farmAppUuid/:analyzedAppId"
                    name="Malicious Report"
                    component={MaliciousReport}
                  />
                )}
                {newQuokkaReport && (
                  <Route
                    path="/new-report/:farmAppUuid/:analyzedAppId"
                    name="New Report"
                    component={NewReport}
                  />
                )}
                <Route
                  path="/settings/:tabName"
                  name="Settings"
                  component={Settings}
                />
                <Route
                  path="/csv-downloads"
                  name="CSV Downloads"
                  component={CsvDownloads}
                />
                <Route
                  path="/analyzed-app/:analyzedAppId/all-versions"
                  name="App All Versions"
                  component={AppAllVersions}
                />
                <Route
                  path="/analyzed-app/:analyzedAppId/compare-versions/:comparisonAppId"
                  name="App Compare Versions"
                  component={AppCompareVersions}
                />
                <Route
                  path="/mdm-integration"
                  name="MDM Integration"
                  exact
                  component={MdmIntegration}
                />
                <Route
                  path="/mdm-integration/devices/:deviceUuid"
                  name="MDM Device Details"
                  component={MdmDeviceDetails}
                />
                <Route
                  path="/notifications"
                  name="Notifications"
                  component={Notifications}
                />
                <Route
                  path="/analyzed-firmware"
                  exact
                  name="Firmware Analysis"
                  component={AnalyzedFirmware}
                />
                <Route
                  path="/analyzed-firmware/:firmwareImageId/apps"
                  name="Firmware Apps"
                  component={FirmwareApps}
                />
                <Route
                  path="/doppler-results"
                  name="Doppler Results"
                  component={DopplerResults}
                />
                <Route
                  path="/download"
                  name="Downloader"
                  component={Downloader}
                />
                <Redirect
                  from="/"
                  to={
                    access.can_view_analyzed_apps ? '/dashboard' : '/submit-app'
                  }
                />
                {!access.can_view_analyzed_apps && (
                  <Redirect from="/dashboard" to="/" />
                )}
              </Switch>
            </Container>
          </main>
          <Aside restartTour={this.restartTour} />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.emmApp.portalConfig.lang,
  sidebarCollapsed: state.emmApp.sidebarCollapsed,
});

const mapDispatchToProps = {
  setMaxSubmissionsReached,
  setTotalSubmissions,
  setLicenseInfo,
  setThreatItems,
  setUserNotifications,
  setUserAccessData,
  setUserConfigData,
  setPasswordChangeOpen,
  setTwoFactorAuthEnabled,
  setIsTrialUser,
  setExpiredDate,
  setUserSubGroups,
  setGroupAdminUsers,
  setGroupAdminSubGroups,
  fetchGroupAdminUsers,
  fetchGroupAdminSubGroups,
  fetchGroupAdminGroup,
  setGroupAdminGroup,
  setGroupNotifications,
  clearAllCache,
};

export default withRouter(
  withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Full)),
  ),
);
