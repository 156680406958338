import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';

import auth from '../logic/auth';
import util from '../logic/util';
import { toastrHelper } from '../logic/toastrHelper';
import i18n from '../localization/i18n';
import { QTypography } from '../components/Q-Components/QTypography';
import { QContainer } from '../components/Q-Components/QContainer';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QCard } from '../components/Q-Components/QCard';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QCardImg } from '../components/Q-Components/QCardImg';
import { QButton } from '../components/Q-Components/QButton';
import { QInputGroup } from '../components/Q-Components/QInputGroup';
import { QInput } from '../components/Q-Components/QInput';
import { QAlert } from '../components/Q-Components/QAlert';
import { QInputGroupAddon } from '../components/Q-Components/QInputGroupAddon';
import { QInputIconWrapper } from '../components/Q-Components/QInputIconWrapper';
import { getAssets } from '../assets/assets';

import { ForgotPasswordModal } from './ForgotPasswordModal';

const appliance = process.env.REACT_APP_APPLIANCE ?? 'default';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      token: '',
      errorMsg: '',
      redirectToDashboard: false,
      resetEmail: '',
      showPasswordResetModal: false,
      shouldLoginWithPassword: false,
      loading: false,
      redirectionPath: '/',
    };

    this.checkAuthType = this.checkAuthType.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    const path = window.location.hash.replace('#/login?redirect-path=', '');

    if (path) {
      this.setState({
        redirectionPath: `/${path}`,
      });
    }
  }

  /**
   * Checks the server to see what type of auth to use for the given email.
   * This will determine if the user should be presented a password/token or
   * redirected to a SSO service
   */
  async checkAuthType() {
    this.setState({ loading: true });
    try {
      const response = await axios.get('auth/auth-type', {
        params: { email: this.state.email },
      });

      const { type, redirectUrl } = response.data;

      if (type === 'sso') {
        window.location.replace(redirectUrl);
        return;
      }

      this.setState({ shouldLoginWithPassword: true });
    } catch (err) {
      console.log('Error with getting auth type: ', err);
    }
    this.setState({ loading: false });
  }

  // Attempt to login to the portal
  async handleLogin() {
    // Reset the error message so that it 'pops' back up if there's another error
    this.setState({ errorMsg: '' });

    const { email, password, token } = this.state;
    // Check to make sure the input is valid (valid email and password is present)
    if (!util.isValidEmail(email)) {
      toastrHelper.showWarningToast(
        i18n.t('Please enter a valid email'),
        i18n.t('Warning'),
        this.props.flags?.mastV2,
      );
      return;
    }
    if (!password) {
      toastrHelper.showWarningToast(
        i18n.t('Please enter a password'),
        i18n.t('Warning'),
        this.props.flags?.mastV2,
      );
      return;
    }

    this.setState({ loading: true });

    try {
      const response = await axios.post('emm/login', {
        email,
        password,
        token,
      });

      if (response.status === 401 || response.status === 400) {
        this.setState({ errorMsg: response.data.msg, password: '' });
      } else {
        console.log('Successful login');
        // Add email to user session for session replays in datadog
        if (
          process.env.NODE_ENV === 'production' &&
          process.env.REACT_APP_APPLIANCE === 'default'
        ) {
          datadogRum.setUser({
            id: response.data.id,
            email: response.data.email,
            groupId: response.data.groupId,
            isTrialUser: response.data.isTrialUser,
            isVerified: response.data.isVerified,
            kaiAccess: response.data.kaiAccess,
            name: response.data.name,
            company: response.data.company,
            phone: response.data.phone,
          });
        }
        // Store the auth token in local storage
        localStorage.setItem('token', `Bearer ${response.data.token}`);
        auth.user.authenticated = true;

        // Set the global axios header for authorization
        axios.defaults.headers.common.Authorization =
          localStorage.getItem('token');

        // Redirect to the dashboard after proper login
        // This will allow the conditional rendering of the Redirect component
        // which will bring the user to the dashboard
        this.setState({
          redirectToDashboard: true,
        });
      }
    } catch (err) {
      console.error(err);
      if (err.response.status === 401) {
        this.setState({ errorMsg: err.response.data.msg });
      } else {
        toastrHelper.showErrorToast(
          i18n.t('Error with server while logging in'),
          null,
          this.props.flags?.mastV2,
        );
      }
      console.error('Error with login request');
      console.error(err.response);
    }

    this.setState({ loading: false });
  }

  // Handles key press for the password field, tries to login on the enter key
  handleKeyPress(event) {
    if (event.charCode === 13) {
      event.preventDefault();

      if (this.state.shouldLoginWithPassword) {
        this.handleLogin();
      } else {
        this.checkAuthType();
      }
    }
  }

  handleFocus(event) {
    event.target.style.background = 'none';
  }

  async submitPasswordResetRequest() {
    try {
      const response = await axios.post('emm/forgot-password', {
        email: this.state.resetEmail,
      });
      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t(
            'Password reset request received. Please check your email inbox',
          ),
          null,
          this.props.flags?.mastV2,
        );
      } else
        toastrHelper.showErrorToast(
          i18n.t('Error submitting password reset request'),
          i18n.t('Error'),
          this.props.flags?.mastV2,
        );
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error submitting password reset request'),
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    } finally {
      this.setState({ showPasswordResetModal: false });
    }
  }

  togglePasswordResetModal() {
    this.setState({
      showPasswordResetModal: !this.state.showPasswordResetModal,
    });
  }

  render() {
    const { from } = this.props.location.state || '/';

    // Disable auth token when 2FA is disabled
    const { shouldLoginWithPassword, loading, redirectionPath } = this.state;

    return (
      <div>
        <ForgotPasswordModal
          open={this.state.showPasswordResetModal}
          toggle={this.togglePasswordResetModal.bind(this)}
        />
        <div className="app flex-row align-items-center">
          <QContainer
            fluid
            sx={{ padding: '0px', background: 'none', minWidth: '100%' }}
          >
            <QRow
              id="login-header-row"
              className="justify-content-center"
              sx={{
                justifyContent: 'center',
              }}
            >
              <div className="banner-logo" />
            </QRow>
            {this.state.errorMsg && (
              <QRow
                className="justify-content-center"
                sx={{ justifyContent: 'center', padding: '10px' }}
              >
                <QCol md="4">
                  {this.props.flags.mastV2 ? (
                    <QAlert
                      variant="outlined"
                      severity="error"
                      title={
                        <QTypography variant="bodyBold">
                          {i18n.t('Error')}:&nbsp;{this.state.errorMsg}
                        </QTypography>
                      }
                    />
                  ) : (
                    <QAlert color="danger">
                      <strong>{i18n.t('Error')}:&nbsp;</strong>
                      {this.state.errorMsg}
                    </QAlert>
                  )}
                </QCol>
              </QRow>
            )}

            <QRow
              className="justify-content-center"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <QCol md="3">
                <QCard
                  className="p-4 login-banner"
                  sx={{ width: '600px', padding: '24px' }}
                >
                  {appliance !== 'default' && (
                    <QCardImg
                      image={getAssets[appliance].logo.login}
                      src={getAssets[appliance].logo.login}
                      sx={{
                        height: 250,
                        transform: 'scale(0.95)', // Scale the image by 120%
                        transformOrigin: 'center', // Keep the scaling centered
                      }}
                      title="login-logo"
                    />
                  )}
                  <QCardBody className="card-body text-center">
                    {appliance === 'default' && (
                      <>
                        {!this.props.flags.mastV2 ? (
                          <>
                            <h3>{i18n.t('Q-MAST Portal Login')}</h3>
                            <p className="text-muted">
                              {i18n.t('Log in to your account')}
                            </p>
                          </>
                        ) : (
                          <>
                            <QRow sx={{ justifyContent: 'center' }}>
                              <QTypography variant="h3Bold">
                                {i18n.t('Q-MAST Portal Login')}
                              </QTypography>
                            </QRow>
                            <QRow sx={{ justifyContent: 'center', mt: 1 }}>
                              <QTypography variant="bodyLight">
                                {i18n.t('Log in to your account')}
                              </QTypography>
                            </QRow>
                          </>
                        )}
                      </>
                    )}
                    {!shouldLoginWithPassword && (
                      <QRow sx={{ justifyContent: 'center', mt: 1 }}>
                        <QInputGroup className="mb-3">
                          {!this.props.flags.mastV2 && (
                            <QInputGroupAddon addonType="prepend">
                              <span className="input-group-text">
                                <i className="icon-user" />
                              </span>
                            </QInputGroupAddon>
                          )}
                          <QInput
                            autoFocus={!this.props.flags.mastV2}
                            sx={{ paddingBottom: '10px' }}
                            type="text"
                            name="email"
                            data-testid="test-login-email-input"
                            className="user-email-input"
                            placeholder={i18n.t('Email')}
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                            onKeyPress={e => this.handleKeyPress(e)}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <QInputIconWrapper
                                  position="end"
                                  sx={{ padding: '5px' }}
                                >
                                  <i className="icon-user" />
                                </QInputIconWrapper>
                              ),
                            }}
                            fullWidth
                          />
                        </QInputGroup>
                      </QRow>
                    )}

                    {shouldLoginWithPassword && (
                      <div className="animated fadeIn">
                        <QRow sx={{ justifyContent: 'center', mt: 1 }}>
                          <QInputGroup className="mb-3">
                            {!this.props.flags.mastV2 && (
                              <QInputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  <i className="icon-lock" />
                                </span>
                              </QInputGroupAddon>
                            )}
                            <QInput
                              autoFocus
                              type="password"
                              className="user-password-input"
                              data-testid="test-login-password-input"
                              placeholder={i18n.t('Password')}
                              variant="outlined"
                              onKeyPress={e => this.handleKeyPress(e)}
                              sx={{ paddingBottom: '10px' }}
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                              InputProps={{
                                startAdornment: (
                                  <QInputIconWrapper
                                    position="end"
                                    sx={{ padding: '5px' }}
                                  >
                                    <i className="icon-lock" />
                                  </QInputIconWrapper>
                                ),
                              }}
                              fullWidth
                            />
                          </QInputGroup>
                        </QRow>

                        <QRow sx={{ justifyContent: 'center', mt: 1 }}>
                          <QInputGroup>
                            {!this.props.flags.mastV2 && (
                              <QInputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  <i className="icon-clock" />
                                </span>
                              </QInputGroupAddon>
                            )}
                            <QInput
                              type="text"
                              className="user-token-input"
                              placeholder={i18n.t('Token')}
                              variant="outlined"
                              onKeyPress={e => this.handleKeyPress(e)}
                              onChange={e =>
                                this.setState({ token: e.target.value })
                              }
                              InputProps={{
                                startAdornment: (
                                  <QInputIconWrapper
                                    position="end"
                                    sx={{ padding: '5px' }}
                                  >
                                    <i className="icon-clock" />
                                  </QInputIconWrapper>
                                ),
                              }}
                              fullWidth
                            />
                          </QInputGroup>
                        </QRow>
                        <QRow style={{ justifyContent: 'center', mt: 1 }}>
                          <i>
                            <QTypography variant="smallRegular">
                              *{' '}
                              {i18n.t(
                                'Only required if two factor authentication enabled',
                              )}
                            </QTypography>
                          </i>
                        </QRow>
                      </div>
                    )}

                    <QRow className="mt-3" sx={{ justifyContent: 'center' }}>
                      <QCol xs="12">
                        {shouldLoginWithPassword ? (
                          <>
                            <QRow sx={{ justifyContent: 'center', mt: 1 }}>
                              <QButton
                                block
                                color="primary"
                                className="px-4 user-login-btn"
                                data-testid="test-login-button"
                                onClick={() => this.handleLogin()}
                                disabled={loading}
                                variant="filled"
                              >
                                {loading ? (
                                  <i className="fa-solid fa-spinner fa-spin" />
                                ) : (
                                  <>
                                    <i className="fa-solid fa-arrow-right-to-bracket" />
                                    &nbsp;{i18n.t('Login')}
                                  </>
                                )}
                              </QButton>
                            </QRow>

                            <div
                              className="forgotten-password-text"
                              data-testid="forgotten-password-test-id"
                              aria-label={i18n.t('Forgot password?')}
                            >
                              <button
                                type="button"
                                className="button-like-div"
                                onClick={() =>
                                  this.setState({
                                    showPasswordResetModal: true,
                                  })
                                }
                                aria-pressed="false"
                              >
                                {i18n.t('Forgot password?')}
                              </button>
                            </div>
                          </>
                        ) : (
                          <QCol>
                            <QRow sx={{ justifyContent: 'center', mt: 1 }}>
                              <QButton
                                block
                                color="primary"
                                className="px-4"
                                onClick={() => this.checkAuthType()}
                                disabled={loading}
                                variant="filled"
                                data-testid="test-login-next-button"
                              >
                                {loading ? (
                                  <i className="fas fa-spinner fa-spin" />
                                ) : (
                                  <>{i18n.t('Next')}</>
                                )}
                              </QButton>
                            </QRow>
                            {this.props.flags.mastV2 && (
                              <div
                                className="forgotten-password-text"
                                data-testid="forgotten-password-test-id"
                                aria-label={i18n.t('Forgot password?')}
                              >
                                <button
                                  type="button"
                                  className="button-like-div"
                                  onClick={() =>
                                    this.setState({
                                      showPasswordResetModal: true,
                                    })
                                  }
                                  aria-pressed="false"
                                >
                                  {i18n.t('Forgot password?')}
                                </button>
                              </div>
                            )}
                          </QCol>
                        )}
                      </QCol>
                    </QRow>

                    {/* Redirect if we have a successful login */}
                    {this.state.redirectToDashboard && (
                      <Redirect
                        to={redirectionPath || 'dashboard'}
                        from={from}
                      />
                    )}
                  </QCardBody>
                </QCard>
                <QRow sx={{ paddingTop: '10px', justifyContent: 'center' }}>
                  <QCol xs="12" className="text-center">
                    {process.env.REACT_APP_APPLIANCE &&
                    process.env.REACT_APP_APPLIANCE !== 'default' ? (
                      <QTypography variant="bodyBold">
                        Powered by Quokka
                      </QTypography>
                    ) : (
                      <>
                        <QTypography variant="bodyBold">Quokka</QTypography>
                        <QTypography variant="bodyRegular">
                          - {i18n.t('Mobile Application Security Testing')}
                        </QTypography>
                      </>
                    )}
                  </QCol>
                </QRow>
              </QCol>
            </QRow>
          </QContainer>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  flags: PropTypes.shape({
    mastV2: PropTypes.bool,
  }),
};

Login.defaultProps = {
  flags: {
    mastV2: false,
  },
};

export default withLDConsumer()(Login);
