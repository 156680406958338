import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartRadar } from '@fortawesome/pro-regular-svg-icons';

import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QTypography } from '../../Q-Components/QTypography';
import { QRadar } from '../../Q-Components/QRadar';
import { QIconButton } from '../../Q-Components/QIconButton';
import { QModal } from '../../Q-Components/QModal';
import { QModalTitle } from '../../Q-Components/QModalTitle';
import { QModalContent } from '../../Q-Components/QModalContent';
import { QModalActions } from '../../Q-Components/QModalActions';
import { QButton } from '../../Q-Components/QButton';

const Title = ({ toggle }) => (
  <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
    <QTypography variant="h3Medium">Threat Types</QTypography>
  </QModalTitle>
);

const Body = () => {
  const threatsType = [
    {
      name: 'Trojan',
      description:
        'Malware that disguises itself as a legitimate app. Once installed it then delivers further threats such as spying on the user or altering the device.',
    },
    {
      name: 'Dropper',
      description:
        ' A malicious program that installs another file (usually a harmful program) onto a device.',
    },
    {
      name: 'Malware',
      description:
        'Software designed in order to attack a user/device, often to steal data or cause damage to devices and systems.',
    },
    {
      name: 'Spyware',
      description:
        "Malware designed to gather information from a device and user in order to send to a third-party without the user's consent.",
    },
    {
      name: 'Infostealer',
      description:
        "A type of malware that disguises itself in order to collect sensitive information and send to a third-party without the user's consent",
    },
    {
      name: 'Backdoor',
      description:
        'Malware that allows threat actors to bypass security measures and gain higher level access to a system.',
    },
    {
      name: 'Ransomware',
      description:
        'A malicious program that prevents users from accessing their device normally, usually by encrypting files or by locking the screen until a ransom is paid.',
    },
    {
      name: 'PUA',
      description:
        'Potentially Unwanted Applications (PUA) are applications that may annoy or even pose risks to users and are typically bundled together with other software.',
    },
    {
      name: 'Hacktool',
      description:
        'Riskware that can be used used in order to attack, gain unauthorized access to, and/or extract information from a device.',
    },
    {
      name: 'Dialer',
      description:
        'Malware that attempts to call other numbers in order to fraud users.',
    },
    {
      name: 'Adware',
      description:
        'Malicious software that disrupts users by displaying unwanted advertisements.',
    },
    {
      name: 'Downloader',
      description:
        'A type of malware that access the network and downloads another file (usually a harmful program) onto a device.',
    },
  ];
  return (
    <QModalContent style={{ maxHeight: '450px', overflowY: 'auto' }}>
      {threatsType.map(type => (
        <p key={type.name}>
          <QTypography>
            <strong>{type.name}</strong>: {type.description}
          </QTypography>
        </p>
      ))}
    </QModalContent>
  );
};

const Footer = ({ toggle }) => (
  <QModalActions>
    <QButton variant="filled" color="primary" onClick={() => toggle()}>
      Got it
    </QButton>
  </QModalActions>
);

// Helper function to apply Circle Arc transformation to data
const applyCircleArcScale = data => {
  const getValue = value => Math.sqrt(100 ** 2 - (value - 100) ** 2);

  return Array.isArray(data)
    ? data.map(value => getValue(value))
    : getValue(data);
};

// Use the new data array and transformation
export const ThreatTypeSpiderChart = ({ results = {} }) => {
  const { mastV2 } = useFlags();
  const [isOpen, setIsOpen] = useState(false);

  const toggleInfoModal = () => setIsOpen(!isOpen);

  const transformAndCleanData = results => {
    const transformations = {
      Trojan: ['Trojan', 'Dropper', 'Downloader'],
      Malware: ['Malware'],
      Spyware: ['Spyware', 'Infostealer'],
      Backdoor: ['Backdoor'],
      Ransomware: ['Ransomware'],
      PUA: ['PUA'],
      Hacktool: ['Hacktool'],
      Dialer: ['Dialer'],
      Adware: ['Adware'],
    };

    return Object.entries(transformations).reduce((acc, [key, fields]) => {
      acc[key] = fields.reduce((sum, field) => sum + (results[field] ?? 0), 0);
      return acc;
    }, {});
  };

  const transformData = transformAndCleanData(results);

  const radarData = {
    labels: Object.keys(transformData),
    datasets: [
      {
        label: 'Threat Levels',
        // Apply circle arc scale transformation to data
        data: applyCircleArcScale(Object.values(transformData)),
        borderWidth: 5,

        pointBackgroundColor(context) {
          const value = context.raw;

          if (value < applyCircleArcScale(40)) {
            return '#2196F3'; // Blue for lower threat
          }
          if (value < applyCircleArcScale(70)) {
            return '#FF9800'; // Orange for medium threat
          }
          return '#F44336'; // Red for high threat
        },
      },
    ],
  };

  const cardBodyStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '350px',
  };

  return (
    <>
      <QModal
        open={isOpen}
        isOpen={isOpen}
        toggle={toggleInfoModal}
        title={<Title toggle={toggleInfoModal} />}
        content={<Body />}
        actions={<Footer toggle={toggleInfoModal} />}
      >
        <Title toggle={toggleInfoModal} />
        <Body />
        <Footer toggle={toggleInfoModal} />
      </QModal>
      <QCard
        style={{
          height: '100%',
        }}
      >
        {!_.isEmpty(results) ? (
          <>
            <QCardHeader
              tag="h4"
              title={
                <QTypography variant="h5Medium">
                  <span>
                    Threat Type Distribution{' '}
                    <QIconButton sx={{ ml: 2 }} onClick={toggleInfoModal}>
                      <i className="fa-regular fa-circle-info" />
                    </QIconButton>
                  </span>
                </QTypography>
              }
              style={mastV2 ? {} : { backgroundColor: '#FFFFFF' }}
            >
              <span>
                Threat Type Distribution{' '}
                <button
                  type="button"
                  className="button-like-span"
                  onClick={toggleInfoModal}
                  aria-label="More information"
                >
                  <i className="fa-regular fa-circle-info ml-2" />
                </button>
              </span>
            </QCardHeader>
            <QCardBody
              style={{
                ...cardBodyStyle,
              }}
            >
              <QRadar
                data={radarData}
                gradientThreshold={{
                  low: 0,
                  medium: applyCircleArcScale(40) / 100,
                  high: applyCircleArcScale(70) / 100,
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                }}
              />
            </QCardBody>
          </>
        ) : (
          <QCardBody
            style={{
              ...cardBodyStyle,
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <FontAwesomeIcon icon={faChartRadar} size="4x" />
            <h5>
              <QTypography variant="h5medium" component="h5">
                Threat Type Distribution unavailable
              </QTypography>
            </h5>
          </QCardBody>
        )}
      </QCard>
    </>
  );
};
